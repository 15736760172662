.main-container {
	height: fit-content;
	margin-top: 7rem;
}
.hero-section-container {
	width: 90%;
	max-width: 1296px;
	max-height: 890px;
	margin: auto;
	height: fit-content;
	display: grid;
	grid-template-columns: minmax(0, 1fr) 1fr;
}
.slant_div {
	background-image: linear-gradient(to bottom right, black, grey);
	width: 100%;
	max-width: 1440px;
	/* max-width: 1296px; */
	margin: auto;
	height: 90vh;
	max-height: 590px;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	transform: skewY(5deg);
	/* transform: rotateX(5deg); */
	/* transform-origin: top ; */
	z-index: -10;
}

.section-container {
	width: 90%;
	max-width: 1296px;
	margin: auto;
	margin-bottom: 20px;
	margin-top: 20px;
}
.hero-left {
	height: 100%;
	max-height: 500px;
	display: grid;
	grid-template-rows: 1fr 2.5fr;
}
.hero-left-top {
	height: 100%;
	padding: 1%;
	font-size: large;
	overflow: hidden;
	color: white;
}
.hero-left-top h1 {
	font-size: xx-large;
}
.hero-left-top p {
	color: rgb(143, 143, 143);
}
/* bottom drop zone */
.hero-left-bottom {
	padding: 20px;
	border: 1px solid #ebebeb;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	background-color: white;
	height: 100%;
	max-height: 100%;
	overflow: hidden;
}
.hero-right {
	padding: 10px;
}

/* Calculation CSS*/
.card-cs {
	padding: 20px;
	border: 1px solid #ebebeb;
	box-shadow: 0 18px 32px -9px #27252538;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.card-cs1 {
	padding: 20px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.card-cs2 {
	padding: 20px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.calculation {
	height: fit-content;
	background-color: white;
	display: flex;
	flex-direction: column;
}
.total-cost-container {
	/* display: grid; */
	display: flex;
	min-height: 250px;
	/* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 1fr; */
	z-index: 1;
}

.total-cost {
	width: 95%;
	margin-right: 10px;
	margin-bottom: 10px;
	grid-column: 1/2;
	overflow: hidden;
}
.total-cost h1 {
	font-size: xx-large;
}
.recommended-machine-card {
	grid-column: 3/4;
	margin-right: 10px;
	margin-bottom: 10px;
	width: 100%;
}
.optomet-card {
	grid-column: 2/3;
}
.optomet-logo {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	margin-bottom: 3rem;
}
.optomet-logo img {
	width: 100%;
	height: auto;
}
/* .optomet-logo animation*/
.clickable:hover {
	animation: pulse 0.5s ease-in-out;
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

/* end optomet-logo animation */
.optomet-logo div {
	display: flex;
	/* justify-content: center; */
	align-items: center;
}
.machine-container {
	display: grid;
	height: 50%;
	grid-template-columns: 1fr 1fr 1fr;
}
.costh2 {
	/* margin-top: 20px; */
	font-size: large;
	color: #31a246;
	background: #eef6f0;
	border-color: #b7eb8f;
	padding: 8px;
	border-radius: 8px;
}
.split_cost_container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.splitCost {
	padding: 7px;
	display: flex;
	justify-content: space-between;
	font-size: medium;
	color: gray;
}

.splitCost span {
	color: black;
}
.machine {
	/* height: 50%; */
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	margin-bottom: 10px;
	margin-top: 20px;
}
.recommended-machine-card {
	/* display: flex; */
	/* flex-direction: column; */
	/* grid-template-rows: 1fr 1fr; */
}
.machine_name_container {
	/* background-color: blue; */
}
.contact_us_btn_container {
	display: flex;
	/* flex-direction: column; */
	justify-content: flex-end;
	align-items: flex-end;
	/* background-color: red; */
	display: grid;
	grid-template-columns: 2fr 1fr;
}
.contact_us_btn_container div {
	display: flex;
	align-items: center;
	justify-content: center;
}
.contact_us_btn {
	background-color: black;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.contact_us_btn:hover {
	background-color: white;
	/* border: 1px solid black; */
	outline: 1px solid black;
	color: black;
}

/* Media Queries for Responsive Free Costing */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.hero-section-container {
		display: grid;
		grid-template-columns: minmax(0, 1fr);
	}
	.hero-left {
		height: 100%;
		max-height: 500px;
		display: grid;
		grid-template-rows: auto;
	}
	.hero-right {
		height: 300px;
	}

	.total-cost-container {
		min-height: 500px;

		z-index: 1;
		display: flex;
		flex-direction: column;
	}
	.total-cost {
		width: 100%;
		margin-right: 10px;
		margin-bottom: 10px;
		grid-row: 1/2;
		overflow: hidden;
	}
	.recommended-machine-card {
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.contact_us_btn_container {
		display: grid;
		grid-template-rows: 2fr 1fr;
		grid-template-columns: 1fr;
		align-items: center;
	}
	/* .contact_us_btn_container div button {
    margin-top: 10px;
  } */
}
/* body {
	color: rgb(32, 96, 125);
} */
@media only screen and (min-width: 600px) and (max-width: 768px) {
	.hero-right {
		position: relative;
	}
	.hero-right div {
		height: 82%;
		width: 95%;
		position: absolute;
		bottom: 0;
	}
	.total-cost-container {
		min-height: 100px;
	}

	.total-cost > h1 {
		font-size: x-large;
		/* color: red; */
	}
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
	.total-cost-container {
		min-height: 100px;
	}

	.total-cost > h1 {
		font-size: x-large;
		/* color: red; */
	}
}
/* 
@media only screen and (min-width: 1200px) {
  ...;
} */
