.error-page {
	height: 100%;
	display: grid;
	justify-content: center;
	align-items: end;
	font-family: sans-serif;
}

.error-page h1 {
	font-size: 5rem;
	color: black;
}
.error-page p {
	font-size: 2rem;
	display: flex;
	justify-content: center;
}

.error-page a {
	background-color: var(--colorprimary);
	font-size: 2rem;
	display: flex;
	justify-self: center;
	padding: 1rem;
	border-radius: 1rem;
}

.error-page a {
	color: white;
}

.error-page a:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	background-color: var(--colorprimarydark);
}
