.stl-main-container {
  width: 90%;
  max-width: 1296px;
  height: 100%;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: darkgreen; */
  /* background-color: white; */

  margin: auto;
}
.stl-viewser-container {
  /* background-color: red; */
  background-color: white;
  width: 100%;
  max-height: 500px;
  max-width: 1296px;
  margin: auto;
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .stl-main-container {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 768px) {
}
