.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-family: "DM Sans", sans-serif;
}

.bannerSection {
  position: relative;
  background-image: url("../../Assets/Images/Desktop - 2.png");
  background-size: 100vw;
  padding: 18rem 0 0 5rem;
  height: 100vh;
  width: 100%;
  display: flex;
}

.macbook {
  /* position: absolute; */
  /* top: 15rem; */
  /* right: 5rem; */
  height: 80%;
}

.bannerText {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.bannerText p {
  color: white;
  font-size: 5rem;
  font-weight: 500;
  text-shadow: 1px 2px 3px black;
  line-height: 7rem;
}

.bannerText button:hover {
  box-shadow: white 0px 3px 8px;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  cursor: pointer;
}

.bannerText button {
  background: #bcf6ff;
  color: #10396a;
  border: none;
  font-size: 3rem;
  font-weight: 600;
  padding: 2rem 2rem;
  border-radius: 1rem;
  width: 40%;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.informationSection {
}

.infoSubSection {
  display: flex;
}

.infoHeader {
  background-image: linear-gradient(180deg, #b5f0f9, white);
  font-size: 4.5rem;
  color: #10396a;

  width: 75%;
  display: flex;
}

.slantBorder {
  width: 0;
  height: 0;
  border-bottom: 5rem solid white;
  border-left: 5rem solid transparent;
  border-top: 5rem solid transparent;
  border-right: 5rem solid white;
}

.footer span {
  color: gray;
}
.footer {
  color: #10396a;
  height: 30vh;
  background-color: #eafcff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  font-size: 3rem;
}
