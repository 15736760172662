@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

#chartdiv {
	width: 100%;
	height: 28rem;
}
.chart-container {
	display: grid;
	grid-template-columns: 3fr 2fr;
	gap: 1rem;
}

.chart-container > div {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.gauge-chart {
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: center;
	overflow: hidden;
}

.chart-right-container {
	display: grid;
	grid-template-rows: 2fr 1fr;
	justify-content: center;
	font-size: 1.8rem;
}

.chart-left-bottom {
	width: 100%;
}

.chart-right-details > div {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #71726d;
	margin: 4rem;
	font-family: "Poppins";
	font-size: 2.5rem;
	font-weight: 600;
}

.chart-right-details p {
	font-size: medium;
}

.chart-right-contact {
	/* display: flex; */
	font-size: x-small;
	padding-bottom: 1rem;
}

.chart-right-container div p {
	display: flex;
	justify-content: center;
	margin: 0 2rem;
	color: gray;
}

.chart-left-bottom ul {
	width: 100%;
	padding: 1rem 5px 1rem 2rem;
	margin: 0 auto;
	box-shadow: 1px 0 3px rgba(0, 0, 0, 0.12), 1px 0 3px rgba(0, 0, 0, 0.24);
}

.chart-left-bottom li.list-item {
	display: inline-flex;
	list-style-type: none;
	padding: 5px;
	margin: 0 0 0 2.5rem;
	border-radius: 3px;
	font-size: small;
	font-weight: 500;
	position: relative;
	color: #71726d;
}

.chart-left-bottom li.list-item:before {
	content: "";
	display: block;
	width: 1.2rem;
	height: 1.2rem;
	position: absolute;
	left: -1.2rem;
	top: 7px;
	outline: 1px solid darkgrey;
}

.chart-left-bottom li.list-item:nth-child(1)::before {
	background-image: linear-gradient(to right, #f87b56, #f5ae3b);
}
.chart-left-bottom li.list-item:nth-child(2):before {
	background-image: linear-gradient(to right, #f5b538, #e5f11d);
}
.chart-left-bottom li.list-item:nth-child(3):before {
	background-image: linear-gradient(to right, #dbf01a, #6dd910);
}
.chart-left-bottom li.list-item:nth-child(4):before {
	background-image: linear-gradient(to right, #6dd910, #1cbb10);
}
.chart-left-bottom li.list-item:nth-child(5):before {
	background-image: linear-gradient(to right, #14b810, #0f9a42);
}

.chart-right-contact {
	justify-content: flex-end;
	align-items: flex-end;
	display: grid;
	grid-template-columns: 2fr 1fr;
	margin-right: 1rem;
}

.contact-btn {
	margin: 0 auto;
}

@media (max-width: 1170px) {
	g {
		transform: scale(0.8) translate(31.5rem, 32.5rem);
	}
}

@media (max-width: 960px) {
	g {
		transform: scale(0.6) translate(41.5rem, 40rem);
	}
}

@media (max-width: 720px) {
	.chart-container {
		display: grid;
		grid-template-rows: repeat(2, minmax(2.5rem, 1fr));
		gap: 1rem;
	}
	.gauge-chart {
		grid-column: 1/3;
	}
	.chart-right-container {
		grid-column: 1/3;
	}
	g {
		transform: scale(0.8) translate(31.5rem, 30rem);
	}
}

@media (max-width: 500px) {
	.chart-container {
		grid-template-rows: repeat(2, minmax(2.5rem, 1fr));
	}
	g {
		transform: scale(0.6) translate(41.5rem, 40rem);
	}
	.chart-left-bottom li.list-item {
		font-size: 1rem;
	}
	.chart-left-bottom li.list-item:before {
		left: -1.2rem;
		top: 4px;
	}
}

@media (max-width: 380px) {
	.chart-right-container {
		font-size: medium;
	}
	g {
		transform: scale(0.5) translate(50rem, 40rem);
	}
}

@media (max-width: 320px) {
	.chart-right-container {
		font-size: small;
	}
	g {
		transform: scale(0.45) translate(55.5rem, 40rem);
	}
}
