.main-nav {
  /* background-color: blue; */
  width: 100%;
  max-width: 1440px;
  margin: auto;
  height: 7rem;
  display: grid;
  grid-template-columns: 3rem 1fr 2fr 1fr 3rem;
  border-bottom: 1px solid grey;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  /* background-image: linear-gradient(to right, black, grey); */
  top: 0;
  z-index: 10;
}
.logo {
  grid-column: 2/3;
  display: flex;
  justify-content: flex-start;
  font-size: 30px;
  align-items: center;
  /* border: 2px solid red; */
}
.logo img {
  /* width: 50%;
  /* width: 50%;
  height: 70%; */
  width: 170px;
  height: auto;
  display: block;
  /* border: 2px solid black; */
  /* margin: auto; */
  width: 170px;
  height: auto;
  display: block;
  /* border: 2px solid black; */
  /* margin: auto; */
}
/* .menu-links ul {
	display: flex;
	align-items: center;
	height: 100%;
	font-size: 25px;
	justify-content: space-around;
	list-style: none;
}
.menu-links ul li a {
	text-transform: capitalize;
	text-decoration: none;
	font-size: 2rem;
	color: black;
}
.menu-links ul li a:hover {
	color: orange;
	border-bottom: 0.1rem solid orange;
	padding-bottom: 0.5rem;
}
.login-signup {
	display: flex;
	align-items: center;
	justify-content: center;
} */
.btn {
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  font-weight: 600;
  margin: 0 10px;
  width: 100px;
  padding: 10px 0;
  /* box-shadow: 0 0px 20px 0px rgba(0, 0, 255/20%); */
  /* x y blur spread */
  transition: 0.4s;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  font-weight: 600;
  margin: 0 10px;
  width: 100px;
  padding: 10px 0;
  /* box-shadow: 0 0px 20px 0px rgba(0, 0, 255/20%); */
  /* x y blur spread */
  transition: 0.4s;
}

.log-in {
  color: #4b4b4d;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid var(--colorprimary);
  color: #4b4b4d;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid var(--colorprimary);
}
/* .sign-up {
	color: white;
	background-color: rgba(104, 85, 224, 1);
	background-color: var(--colorprimary);
} */
.log-in:hover {
  color: white;
  /* width: ; */
  /* box-shadow: 0 0 25px 5px lightgrey; */
  background-color: var(--colorprimary);
  box-shadow: 0 18px 32px -9px #27252538;
  color: white;
  /* width: ; */
  /* box-shadow: 0 0 25px 5px lightgrey; */
  background-color: var(--colorprimary);
  box-shadow: 0 18px 32px -9px #27252538;
}
/* .sign-up:hover {
	background-color: var(--colorprimarydark);
	box-shadow: 0 18px 32px -9px #27252538;
} */

/* Test Navbar Classes */
.navbar-right {
  grid-column: 4/5;
  display: flex;
  align-items: center;
}

.navbar-menu {
  display: flex;
  list-style: none;
  display: flex;
  list-style: none;
}

.navbar-item {
  margin-right: 1rem;
  cursor: pointer;
  margin-right: 1rem;
  cursor: pointer;
}

.navbar-dropdown {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  padding: 2.5rem;
  color: gray;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  padding: 2.5rem;
  color: gray;
}

.navbar-dropdown-header {
  display: flex;
  align-items: center;
  gap: 2px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.navbar-dropdown:hover {
  background-color: hsla(240, 4%, 77%, 0.2);
  color: black;
  transition: 0.2s linear;
  background-color: hsla(240, 4%, 77%, 0.2);
  color: black;
  transition: 0.2s linear;
}

.navbar-dropdown:hover .navbar-dropdown-menu {
  display: block;
  transition: 0.1s linear;
  display: block;
  transition: 0.1s linear;
}

.navbar-dropdown-menu {
  display: none;
  position: absolute;
  top: 6.2rem;
  right: 0;
  background-color: #fff;
  list-style: none;
  padding: 1rem 0;
  margin-top: 0.5rem;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
  outline: 0.5px solid gray;
  border-radius: 2px;
  display: none;
  position: absolute;
  top: 6.2rem;
  right: 0;
  background-color: #fff;
  list-style: none;
  padding: 1rem 0;
  margin-top: 0.5rem;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
  outline: 0.5px solid gray;
  border-radius: 2px;
}

.navbar-dropdown-menu a {
  cursor: pointer;
  padding: 1rem 2.5rem;
  cursor: pointer;
  padding: 1rem 2.5rem;
}

.navbar-dropdown-menu a {
  color: black;
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 5px;
  color: black;
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 5px;
}

.navbar-dropdown-menu .navbar-dropdown-menu-header {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.navbar-dropdown-menu .navbar-dropdown-menu-header h1 {
  font-size: 2rem;
  font-size: 2rem;
}

.navbar-dropdown-menu .navbar-dropdown-menu-header h4 {
  font-size: 1rem;
  color: gray;
  font-size: 1rem;
  color: gray;
}
.navbar-dropdown-menu a img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: auto;
}
.navbar-dropdown-menu a:hover {
  background-color: hsla(240, 4%, 77%, 0.2);
  text-decoration-color: orange;
  background-color: hsla(240, 4%, 77%, 0.2);
  text-decoration-color: orange;
}

.navbar-login {
  margin-left: auto;
  cursor: pointer;
  margin-left: auto;
  cursor: pointer;
}

/* New Exploere landing page css */
.explorer-landing-nav {
  width: 100%;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 1px 0 gray;
}

.explorer-landing-nav .explorer-logo {
  margin-left: 4rem;
  font-family: "DM Sans", sans-serif;
}

.explorer-landing-nav .nav-links {
  display: flex;
  gap: 1rem;
  font-size: 2rem;
  margin-right: 4rem;
}

.nav-links div {
  padding: 1rem 2rem;
  background: #bcf6ff;
  border-radius: 1rem;
  font-weight: 500;
  cursor: pointer;
}

.nav-links .request-demo {
  color: #194271;
  transition: 0.3s all ease;
}

.nav-links .request-demo:hover {
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.nav-links .download-report {
  color: #194271;
  outline: 1px solid #194271;
  background: white;
  transition: 0.3s all ease;
}

.nav-links .download-report:hover {
  background: #194271;
  color: white;
}
