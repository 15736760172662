.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

.popup.active {
  opacity: 1;
  visibility: visible;
}

.popup-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 35%;
  transition: all 0.3s ease-in-out;
}

.popup-description {
  font-size: 1.7rem;
}

.popup-logo {
  display: grid;
  grid-template-columns: 6fr 1fr;
  justify-content: center;
}

.close-btn:hover {
  transform: scale(2);
}

.close-btn {
  transition: all 0.3s ease;
  cursor: pointer;
  transform: scale(1.5);
  border: none;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-self: end;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.popup-logo img {
  width: 50%;
}

.popup-form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 5px;
  font-size: 1.8rem;
  box-shadow:
    rgba(0, 0, 0, 0.12) 0px 1px 3px,
    rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.popup-form-container form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.popup-form-container form input:focus-visible {
  outline: 2px solid #e9c632;
  border-radius: 5px;
}

.popup-form-container form input {
  height: 5rem;
  font-size: 2rem;
  border: none;
  border-bottom: 2px solid #e9c632;
}

.popup-form-container button {
  font-size: 1.8rem;
  border: none;
  background-color: #ffd93c;
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
}

.popup-form-container button:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
