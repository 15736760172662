/* File Uplader CSs */
.dropzone {
	border: 2px dashed rgba(87, 85, 85, 0.7);
	border-radius: 10px;
	padding: 50px 0;
	text-align: center;
	text-transform: uppercase;
	color: rgba(74, 73, 73, 0.9);
	font-size: medium;
	height: 40%;
	display: flex;
	overflow: hidden;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	text-align: center;
}
.dropzone input {
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	text-align: center;
	opacity: 0;
	cursor: pointer;
}
.dropzone:hover {
	opacity: 0.6;
}
.dropzone.active {
	border-color: black;
}
.uploader-input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	border-color: #dedede;
	border: 1px solid #aeaeae;
	cursor: no-drop;
	background: #d8d8d8;
	text-shadow: none;
	box-shadow: none;
}
.drop_down_material_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	margin-top: 10px;
	height: 40px;
}

.upload-btn-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;
}
.upload-btn-container p {
	font-size: large;
	padding: 10px;
}
.upload-btn {
	width: 100%;
	height: 40px;
	background-color: var(--colorprimary);
	border-radius: 10px;
	border: 0;
	color: white;
	font-weight: 500;
	font-size: large;
}
.upload-btn:hover {
	background-color: var(--colorprimarydark);
	box-shadow: 0 7px 32px -9px #27252538;
	cursor: pointer;
}

.upload-btn[disabled] {
	color: rgba(0, 0, 0, 0.25);
	border-color: #dedede;
	border: 1px solid #aeaeae;
	cursor: no-drop;
	background: #d8d8d8;
	text-shadow: none;
	box-shadow: none;
}

/* CSS for Drop Down for choosing material */
.dropdown {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
}

.dropdown-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 1rem;
	padding: 0 0.5rem;
}

.dropdown-component {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.dropdown-component h2 {
	width: 100%;
	text-align: left;
}

.dropdown select {
	padding: 7px;
	font-size: 16px;
	border: none;
	border-radius: 4px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	color: #333333;
	cursor: pointer;
	outline: 1px solid grey;
}

.dropdown select:hover {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.dropdown select:focus {
	outline: none;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	outline: 1px solid darkgrey;
}

.dropdown select option {
	background-color: #ffffff;
	color: #333333;
	font-weight: normal;
}

.dropdown select option:hover {
	background-color: #f5f5f5;
}

.input-error {
	margin-top: 3px;
	font-size: medium;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #2d3748;
	background: #fed7d7;
	border-color: #b7eb8f;
	padding: 8px;
	border-radius: 8px;
}

/* CSS for FreeExplorer page upload component*/

.progress-area {
	background: #e9f0ff;
	display: grid;
	grid-template-columns: 1fr 8fr;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 1rem 4rem;
	border-radius: 1rem;
	gap: 1rem;
}
.content .details {
	display: flex;
	justify-content: space-between;
}

.details span {
	font-size: 1.3rem;
}

.progress-area .details {
	display: flex;
	align-items: center;
	margin-bottom: 7px;
	justify-content: space-between;
}
.progress-area .content .progress-bar {
	height: 6px;
	width: 100%;
	margin-bottom: 4px;
	background: #fff;
	border-radius: 30px;
}
.content .progress-bar .progress {
	height: 100%;
	width: 0%;
	background: #6990f2;
	border-radius: inherit;
}
.uploaded-area {
	display: grid;
	grid-template-columns: 1fr 7fr 1fr;
	align-items: center;
	width: 100%;
	height: auto;
	font-size: 1rem;
	background: #e9f0ff;
	padding: 1rem 4rem;
	border-radius: 1rem;
}

.uploaded-area svg {
	justify-self: center;
}
.uploaded-area .details {
	display: flex;
	flex-direction: column;
}

/*  Media Queries --------------------------------- */
@media only screen and (max-width: 600px) {
	.dropzone {
		font-size: small;
	}
	.drop_down_material_container {
		height: 10px;
	}
	.upload-btn-container p {
		font-size: small;
	}
	.details span {
		font-size: small;
	}
	.upload-btn.custom-font-size {
		font-size: 1.3rem;
	}
	.upload-btn {
		height: 35px;
	}
	.dropdown select {
		width: 100%;
		padding: 5px;
		font-size: 12px;
	}
	.dropdown select option {
		width: min-content;
		font-size: 12px;
	}
	.dropdown h2 {
		font-size: small;
	}
}
@media only screen and (min-width: 600px) and (max-width: 820px) {
	.details span {
		font-size: 1.1rem;
	}
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
	.dropzone {
		font-size: small;
		height: 40%;
	}
	.drop_down_material_container {
		height: 10px;
	}
	.upload-btn-container p {
		font-size: small;
	}
	.upload-btn.custom-font-size {
		font-size: 1.29rem;
	}
	.upload-btn {
		height: 35px;
		font-size: medium;
	}
	.dropdown select {
		width: 100%;
		padding: 5px;
		font-size: 12px;
	}
	.dropdown select option {
		width: min-content;
		font-size: 12px;
	}
	.dropdown h2 {
		font-size: small;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
	.dropzone {
		font-size: small;
		height: 40%;
	}
	.upload-btn-container p {
		font-size: small;
	}
	.upload-btn {
		height: 35px;
		font-size: medium;
	}
	.dropdown select {
		width: 100%;
		padding: 5px;
		font-size: 12px;
	}
	.dropdown select option {
		width: min-content;
		font-size: 12px;
	}
	.dropdown h2 {
		font-size: medium;
	}
}
