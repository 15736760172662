* {
  font-family: "DM Sans", sans-serif;
  margin: 0;
  padding: 0%;
  box-sizing: border-box;
  /* transition: 0.2s linear; */
}

.App {
  display: grid;
  /* height: 50vh; */
}

:root {
  --main-color: #0c0f27;
  --border: 0.1rem solid rgba(255, 255, 255, 0.3);
}

:root {
  --colorprimary: #efa856;
  --colorprimarydark: #f4a03f;
}
html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-padding-top: 9rem;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
